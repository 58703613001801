import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import PublicRoute from './router/PublicRoute';
import Configurator from './components/configurator/Configurator';
import Layout from './components/login/Layout';
import SignUp from './components/login/SignUp';
import ProfileLayout from './components/user-profile/ProfileLayout';
import UserData from './components/user-profile/sections/UserData';
import Addresses from './components/user-profile/sections/Addresses/Addresses';
import AddNewAddress from './components/user-profile/sections/Addresses/AddNewAddress';
import Orders from './components/user-profile/sections/orders/Orders';
import Order from './components/user-profile/sections/orders/Order';
import HelpAndContact from './components/user-profile/sections/HelpAndContact/HelpAndContact';
import Home from './components/home/Home';
import AddPaymentMethod from './components/user-profile/sections/PaymentMethods/AddPaymentMethod';
import PaymentMethods from './components/user-profile/sections/PaymentMethods/PaymentMethods';
import EditPaymentMethod from './components/user-profile/sections/PaymentMethods/EditPaymentMethod';
import CountryScreen from './components/login/CountryScreen';
import DraftPage from './components/draft/DraftPage';
import CartHome from './components/cart/CartHome';
import CartSuccess from './components/cart/CartSuccess';
import ProductsPage from './components/products/ProductsPage';
import SingleProduct from './components/product/SingleProduct';
import PaymentsInfo from './components/aboutInfo/PaymentsInfo';
import TermsOfUse from './components/aboutInfo/TermsOfUse';
import AboutUs from './components/aboutInfo/AboutUs';
import Contact from './components/aboutInfo/Contact';
import Refund from './components/aboutInfo/Refund';
import PrivacyPolicy from './components/aboutInfo/PrivacyPolicy';
import LoginScreen from './components/login/login-screens/LoginScreen';
import ResetPasswordScreen from './components/login/login-screens/ResetPasswordScreen';
import ForgotPasswordScreen from './components/login/login-screens/ForgotPasswordScreen';
import RegistrationScreen from './components/login/login-screens/RegistrationScreen';
import NewPasswordScreen from './components/login/login-screens/NewPasswordScreen';

function AppRouter() {
	return (
		<Routes>
			{/* <Route path='/product' element={<PublicRoute Component={SingleProduct} />} /> */}
			<Route path="*" element={<PublicRoute Component={Home} />} />
			{/* <Route path="/product" element={<SingleProduct />} /> */}
			<Route path="/configurator" element={<Configurator />} />
			<Route path="/configuration" element={<Configurator />} />
			<Route path="/signup" element={<PublicRoute Component={SignUp} />} />
			<Route path="/" element={<Layout />}>
				<Route path="profile/" element={<ProfileLayout />}>
					<Route path="user" element={<UserData />} />
					<Route path="addresses" element={<Addresses />} />
					<Route path="address" element={<AddNewAddress />} />
					<Route path="orders" element={<Orders />} />
					<Route path="order/:selectedOrderId" element={<Order />} />
					<Route path="help" element={<HelpAndContact />} />
					<Route path="paymentmethods" element={<PaymentMethods />} />
					<Route path="newpaymentmethod" element={<AddPaymentMethod />} />
					<Route path="editpaymentmethod" element={<EditPaymentMethod />} />
				</Route>
				{/* <Route index element={<ProductsPage />} /> */}
				<Route path="" element={<Home />} />

				<Route path="login" element={<PublicRoute Component={LoginScreen} />} />
				<Route path="reset-password" element={<PublicRoute Component={ResetPasswordScreen} />} />
				<Route path="forgot-password" element={<PublicRoute Component={ForgotPasswordScreen} />} />
				<Route path="register" element={<PublicRoute Component={RegistrationScreen} />} />
				<Route path="new-password" element={<PublicRoute Component={NewPasswordScreen} />} />
				<Route index path="country" element={<CountryScreen />} />
				<Route index path="drafts" element={<DraftPage />} />
				<Route index path="cart" element={<CartHome />} />
				<Route index path="payment/success" element={<CartSuccess />} />
				<Route index path="cart/checkout" element={<CartHome />} />
				<Route index path="products/:category_name" element={<ProductsPage />} />
				<Route index path="products/:category_name/:subcategory_name" element={<ProductsPage />} />
				<Route index path="product/:productName" element={<SingleProduct />} />
				<Route index path="placanje_i_isporuka" element={<PaymentsInfo />} />
				<Route index path="uslovi_koriscenja" element={<TermsOfUse />} />
				<Route index path="o_nama" element={<AboutUs />} />
				<Route index path="kontakt" element={<Contact />} />
				<Route index path="politika_privatnosti" element={<PrivacyPolicy />} />
				<Route index path="povracaj_sredstava" element={<Refund />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
		</Routes>
	);
}

export default AppRouter;
