import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import { SubmitHandler, useForm } from 'react-hook-form';
import Loading from '../../../common/Loading';
import AuthLayout from '../LoginLayout';
import ForgotPasswordPhoto from '../../../assets/images/ForgotPassword.webp';
import SendAgainBackground from '../../../assets/images/SendAgainBackground.webp';
import SignInPhoto from '../../../assets/images/SignIn.webp';
import { forgotPassword } from '../../../server/server';

type Inputs = {
	email: string;
};

interface SignIn {
	setSelectedMode: any;
}

function ForgotPasswordScreen({ setSelectedMode }: SignIn) {
	const navigate = useNavigate();
	const { setUser } = useUser();
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [currentState, setCurrentState] = useState('init');

	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: {
			email: '',
		},
	});

	const onSubmit: SubmitHandler<Inputs> = async () => {
		resetPassword();
	};
	const resetPassword = async () => {
		setLoading(true);
		const response = await forgotPassword({
			email: getValues('email'),
		});
		if (response.status === 201) {
			const data = response.data;
			setCurrentState(currentState === 'init' ? 'check' : 'init');
		} else {
			setErrorMessage(response?.data?.message);
		}
		setLoading(false);
	};
	return (
		<AuthLayout seoTitle="Forgot Password - Layka" selectedImage={currentState == 'init' ? ForgotPasswordPhoto : SignInPhoto}>
			<div className="w-full h-full bg-white flex flex-col items-center justify-center">
				{currentState === 'init' && (
					<div className="flex flex-col max-w-[430px] w-full px-3 ">
						<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Forgot your Password?</h1>
						<h2 className="text-[16px] leading-[24px] text-black  font-normal  mt-[17px]">Enter your email below to receive your password reset instructions</h2>
						<form className="flex flex-col items-center w-full mt-[17px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<div className="flex flex-col w-full">
								<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
									<input
										className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
										placeholder="Email"
										{...register('email', {
											required: true,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: 'Invalid email address',
											},
										})}
									/>
								</div>
								<span className="text-redError text-[12px] leading-5 h-5 ">
									{errors.email?.type === 'required' ? 'Field is required!' : ''}
									{errors.email?.type === 'pattern' ? 'Email is not valid!' : ''}
								</span>
							</div>

							<p className="text-redError text-[12px] leading-5 h-5 text-center">{!!errorMessage ? errorMessage : ''}</p>
							<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-1 uppercase" disabled={loading}>
								{loading ? <Loading height={25} /> : 'Send'}
							</button>
						</form>

						<Link to="/login" className="w-full flex items-center justify-center  bg-white text-[16px] text-laykaDarkPurple font-bold cursor-pointer mt-10 ">
							Back to login
						</Link>
					</div>
				)}
				{currentState === 'check' && (
					<div style={{ backgroundImage: `url(${SendAgainBackground})`, width: '100%', height: '100%', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className="flex flex-col items-center justify-center">
						<div className="flex flex-col max-w-[300px] w-full px-3 ">
							<p className="text-[30px] leading-[36px] text-black  font-bold  ">Check you email !</p>
							<p className="text-[16px] leading-[24px] text-black  font-normal  mt-[20px]">We have sent a password reset instruction to your email</p>
							<button className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-5 uppercase" disabled={loading} onClick={() => setCurrentState('init')}>
								{loading ? <Loading height={25} /> : 'Resend'}
							</button>
							<img src="" alt="" />
						</div>
					</div>
				)}
			</div>
		</AuthLayout>
	);
}

export default ForgotPasswordScreen;
