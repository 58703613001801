import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../SVG/UnselectedIcons';
import { DropdownActive } from '../../SVG/SelectedIcons';
import AdditionalInformation from './AdditionalInformation';
import { getSingleStaticPages } from '../../../../server/server';
import MessageModal from './MessageModal';
import ReactMarkdown from 'react-markdown';

interface QA {
	id: string;
	question: string;
	answer: string;
	ordinal: number;
}

const HelpAndContact = () => {
	const [dropdownActive, setDropDownActive] = useState<{ [key: string]: boolean }>({});

	const [terms, setTerms] = useState<string>('');
	const [privacy, setPrivacy] = useState<string>('');
	const [modalOpen, setModalOpen] = useState(false);
	const [QA, setQA] = useState<QA[]>([]);

	useEffect(() => {
		fetchSsp();
	}, []);

	const fetchSsp = async () => {
		const resp = await getSingleStaticPages();
		if (resp.status === 200 && resp.data.length > 0) {
			const data = resp.data[0];
			setTerms(data.terms || '');
			setPrivacy(data.privacyPolicy || '');

			const sortedQA = data?.questionsAndAnswers?.sort((a: any, b: any) => a.ordinal - b.ordinal) ?? [];
			setQA(sortedQA);
		} else {
			alert(`Error getting information:${resp.status}`);
		}
	};
	const handleContactClick = () => {
		setModalOpen(true);
	};
	return (
		<div className="w-[522px]">
			<div>
				<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px]">FAQ</h4>
			</div>
			{QA?.map((item, index) => (
				<div
					className={`flex flex-col justify-between justify-items-center w-full  cursor-pointer bg-white ${index === QA.length - 1 ? 'rounded-b-[12px]' : 'border-b'} ${index === 0 ? 'rounded-t-[12px] mt-[20px]' : ''} border-laykaLight`}
					style={{ userSelect: 'none' }}
					onClick={() =>
						setDropDownActive((prevState) => ({
							...prevState,
							[item.id]: !prevState[item.id],
						}))
					}
				>
					<div className="flex flex-row justify-between items-center min-h-[64px] p-[20px]">
						<span className="text-base font-notoSans font-normal text-black">{item?.question}</span>
						<div className="">{!dropdownActive[item.id] ? <Dropdown /> : <DropdownActive />}</div>
					</div>

					{dropdownActive[item.id] && (
						<div className="p-[20px]">
							<ReactMarkdown>{item.answer}</ReactMarkdown>
						</div>
					)}
				</div>
			))}
			<div className="mt-[30px]">
				<AdditionalInformation terms={terms} privacy={privacy} />
			</div>
			<div className="flex flex-col mt-[30px]">
				<p>Got question, suggestion or feedback to share? </p>
				<p>Contact us! We love hearing from you.</p>
			</div>

			<div className="mt-[20px]  md:w-[522px]">
				<button className="w-[130px] md:w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px]" onClick={handleContactClick}>
					Contact us
				</button>
			</div>

			<MessageModal open={modalOpen} onClose={() => setModalOpen(false)} />
		</div>
	);
};

export default HelpAndContact;
