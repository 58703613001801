import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../auth/UseUser';
import ConfirmationModal from './modals/ConfirmationModal';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as DraftIcon } from '../assets/MyDraftIcon.svg';
import { ReactComponent as CartIcon } from '../assets/CartIcon.svg';
import { useCart } from '../hooks/useCart';
import { getImage, getMenuCategories } from '../server/server';
import { useMenuItems } from '../hooks/useMenuItems';
import { useAppContext } from '../hooks/useAppContext';
import { ReactComponent as LaykaStar } from '../assets/icons/laykaStar.svg';

function NavBar() {
	const MemoizedLogo = React.memo(Logo);
	const location = useLocation();
	const { user, setUser, isSignedIn } = useUser();
	const { selectZone } = useAppContext();
	const { cart } = useCart();

	const { menuItems, setMenuItems } = useMenuItems();
	let navigate = useNavigate();
	const [logOutModal, setLogOutModal] = useState(false);
	const [loc, setLoc] = useState('/');

	useEffect(() => {
		const pathList = location.pathname.split('/');
		setLoc('/' + pathList[1]);
	}, [location.pathname]);

	const closeLogOutModal = () => {
		setLogOutModal(false);
	};

	const logout = () => {
		localStorage.clear();
		setUser({});
		selectZone({});
	};

	const navigateToLogin = () => {
		navigate('/login');
	};

	return (
		<div className="w-full bg-[#fff]">
			<div className="max-w-[1280px] w-full mx-auto h-[92px] flex justify-between items-center  px-[20px]  bg-[#fff] text-black">
				<div className="w-fit h-5 justify-center items-center gap-[30px] hidden sm:inline-flex  opacity-0 pointer-events-none ">
					{isSignedIn ? (
						<button onClick={() => navigate('/profile/user')} className="text-black text-sm font-normal leading-[18px] transition-all duration-[230ms] ">
							Profile
						</button>
					) : (
						<button className="text-black text-sm font-normal leading-[18px] transition-all duration-[230ms] " onClick={navigateToLogin}>
							Log in
						</button>
					)}

					{isSignedIn && (
						<div className="justify-start items-center gap-2 flex">
							<button className="text-black text-sm font-normal leading-[18px] flex flex-row items-center justify-center gap-[8px] transition-all duration-[230ms] " onClick={() => navigate('/drafts')}>
								<DraftIcon />
								<span>My draft</span>
							</button>
						</div>
					)}
					<div className="justify-start items-center gap-2 flex">
						<button className="text-black text-sm font-normal leading-[18px] flex flex-row items-center justify-center gap-[8px] transition-all duration-[230ms] " onClick={() => navigate('/cart')}>
							<CartIcon />
							<span>({cart.length})</span>
						</button>
					</div>
				</div>
				<div className="cursor-pointer  w-full  md:w-auto flex items-center" onClick={() => navigate('')} title="Layka Gift">
					<MemoizedLogo className="mx-auto" />
				</div>

				<div className="w-fit h-5 justify-center items-center gap-[30px] hidden sm:inline-flex">
					{/* <div className="text-black text-sm font-normal leading-[18px]">Contact</div> */}
					{isSignedIn ? (
						<button onClick={() => navigate('/profile/user')} className="text-black text-sm font-normal leading-[18px] transition-all duration-[230ms] ">
							Profile
						</button>
					) : (
						<button className="text-black text-sm font-normal leading-[18px] transition-all duration-[230ms] " onClick={navigateToLogin}>
							Log in
						</button>
					)}

					{isSignedIn && (
						<div className="justify-start items-center gap-2 flex">
							<button className="text-black text-sm font-normal leading-[18px] flex flex-row items-center justify-center gap-[8px] transition-all duration-[230ms] " onClick={() => navigate('/drafts')}>
								<DraftIcon />
								<span>My draft</span>
							</button>
						</div>
					)}
					<div className="justify-start items-center gap-2 flex">
						<button className="text-black text-sm font-normal leading-[18px] flex flex-row items-center justify-center gap-[8px] transition-all duration-[230ms] " onClick={() => navigate('/cart')}>
							<CartIcon />
							<span>({cart.length})</span>
						</button>
					</div>
				</div>
			</div>
			<div className="min-h-[26px]  bg-[#fff]  items-center gap-y-1 sm:gap-x-10 xl:gap-x-20  gap-x-5 pt-[2px] flex-wrap border-y border-y-babyBlue hidden md:flex">
				<div className=" xl:max-w-[1280px]  w-full mx-auto justify-between items-center gap-[10px] inline-flex  px-[20px]">
					{menuItems.map((menuItem: any, index: number) => (
						<MenuButton item={menuItem} key={menuItem.id} index={index} />
					))}
				</div>
			</div>
			<ConfirmationModal modalIsOpen={logOutModal} closeModal={closeLogOutModal} confirmation={logout} message={`Одјави се`} />
		</div>
	);
}

const ZoneSelector = () => {
	const [hover, setHover] = useState(false);
	const { zone, zoneList, selectZone } = useAppContext();

	const submit = (zone: any) => {
		selectZone(zone);
		setHover(false);
	};

	return (
		<div className="relative bg-white rounded-[12px]" onMouseLeave={() => setHover(false)}>
			<div className="w-[111px] justify-start items-start gap-5  hidden sm:inline-flex relative">
				<div className="justify-start items-center gap-2 flex cursor-pointer" onClick={() => setHover(true)}>
					<img src={getImage(zone?.icon?.id)} alt={zone?.name} width="16" height="12" />
					<div className="text-black text-sm font-normal leading-[18px]">{zone?.name}</div>
					<div className="w-[9px] h-[9px] relative" />
					<div className="text-black text-sm font-normal leading-[18px]">{zone?.currency?.symbol}</div>
					<div className="w-[9px] h-[9px] relative" />
				</div>
			</div>
			{hover && (
				<div className="absolute flex flex-col b-0 z-[5] bg-white gap-[5px] pt-[10px]">
					{zoneList
						.filter((e: any) => e.id !== zone.id)
						?.map((zone: any, index: number) => {
							return (
								<div key={zone.id} className="flex flex-row items-center gap-[10px] cursor-pointer" onClick={() => submit(zone)}>
									<img src={getImage(zone?.icon?.id)} alt={zone?.name} width="16" height="12" />
									<div className="text-black text-sm font-normal leading-[18px]">{zone?.name}</div>
									<div className="w-[9px] h-[9px] relative" />
									<div className="text-black text-sm font-normal leading-[18px]">{zone?.currency?.symbol}</div>
									<div className="w-[9px] h-[9px] relative" />
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
};

const MenuButton = ({ item, index }: { item: any; index: number }) => {
	const [hover, setHover] = useState(false);
	const navigate = useNavigate();
	const ref = useRef<any>(null);
	const [leftPosition, setLeftPosition] = useState('');
	const showSubmenu = hover && !!item?.subCategories.length;

	const navigateToProductType = (type: string, subCategory = null) => {
		navigate(`/products/${type}${!!subCategory ? `/${subCategory}` : ''}`.toLowerCase());
	};

	useEffect(() => {
		if (ref.current) {
			const { left, right } = ref?.current?.getBoundingClientRect();
			if (left <= 100) {
				setLeftPosition('left-[-20px]');
			} else if (window.innerWidth - right <= 100) {
				setLeftPosition('right-[-20px]');
			} else {
				setLeftPosition('-translate-x-1/2 left-1/2');
			}
		}
	}, [showSubmenu]);

	return (
		<div className="relative" onMouseLeave={() => setHover(false)} ref={ref}>
			<button onMouseEnter={() => setHover(true)} className={` ${hover ? 'text-laykaPink' : 'text-black'} text-[0.875rem] xl:text-base font-medium uppercase leading-normal cursor-pointer flex flex-row items-center gap-[6px] h-[50px]`} onClick={() => navigateToProductType(item?.seo?.urlSlug)}>
				{item.featured && <LaykaStar />}
				<span>{item.name}</span>
				{item.featured && <LaykaStar />}
			</button>
			{!!item?.subCategories.length && (
				<div
					className={`absolute top-[50px] w-[216px] z-[5]  p-[20px] items-start flex flex-col gap-[16px] rounded-bl-[12px] rounded-br-[12px] bg-white border border-[#F1F1FE] ${!showSubmenu ? 'hidden max-h-0 opacity-0' : 'visible opacity-100 max-h-[200px]'} ${leftPosition}`}
					style={{
						transition: 'visibility 0s, opacity 230ms ease-in-out, max-height 230ms ease-in-out',
					}}
				>
					{item?.subCategories.map((subItem: any, index: number) => (
						<button key={subItem.id} className={` text-black  hover:text-laykaPink  text-base font-medium uppercase leading-normal cursor-pointer`} onClick={() => navigateToProductType(item?.seo?.urlSlug, subItem?.seo?.urlSlug)}>
							{subItem.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
};
export default NavBar;
