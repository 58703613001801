import React from 'react';
import SEO from '../seo/seo';

interface AuthLayoutProps {
	children: React.ReactNode;
	selectedImage: string;
	seoTitle: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, selectedImage, seoTitle }) => {
	return (
		<div className="w-full grid grid-cols-1 xl:grid-cols-2 bg-white">
			<SEO title={seoTitle} />
			<div className="bg-white hidden xl:block min-h-[756px]">
				<img src={selectedImage} alt="Authentication" className="w-full object-contain h-full" height={756} width={756} />
			</div>
			<div className="h-full py-10 xl:py-0">{children}</div>
		</div>
	);
};

export default AuthLayout;
