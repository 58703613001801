import React, { useState } from 'react';
import { signUp, whoAmI } from '../../../server/server';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import Loading from '../../../common/Loading';
import { ReactComponent as ShowPasswordIcon } from '../../../assets/icons/ShowPasswordIcon.svg';
import { ReactComponent as HidePasswordIcon } from '../../../assets/icons/HidePasswordIcon.svg';
import SignUpPhoto from '../../../assets/images/SignUp.webp';
import AuthLayout from '../LoginLayout';

type Inputs = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	repeatPassword: string;
};
interface SignIn {
	setSelectedMode: any;
}

function RegistrationScreen({ setSelectedMode }: SignIn) {
	const navigate = useNavigate();
	const { setUser } = useUser();
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		reset,
		trigger,
		formState: { errors, isValid },
	} = useForm<Inputs>({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			repeatPassword: '',
		},
	});

	const onSubmit: SubmitHandler<Inputs> = async () => {
		await signUpUser();
	};

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const signUpUser = async () => {
		setLoading(true);
		console.log('registracija data', getValues());
		const resp = await signUp({
			email: getValues('email'),
			password: getValues('password'),
			firstName: getValues('firstName'),
			lastName: getValues('lastName'),
		});
		if (resp.status === 201) {
			const data = resp.data;
			setErrorMessage('');
			console.log('registration success', data);
			localStorage.setItem('accessToken', data['accessToken']);
			const response = await whoAmI();
			if (response.status === 200) {
				const whoData = response.data;
				if (whoData.valid === false) {
					localStorage.removeItem('accessToken');
					alert('Poslat vam je email za verifikaciju naloga. Molimo vas proverite vas inbox (pogledajte spam ili junk folder ukoliko nije u inboxu).');
					setSelectedMode('login');
					setLoading(false);
					return;
				}
				setUser(whoData);
				localStorage.setItem('user', JSON.stringify(whoData));
				navigate('/products');
			} else {
				console.log('who am i failed ', response.status);
			}
		} else {
			console.log('registration error', resp?.data);
			setErrorMessage(resp?.data?.message);
		}
		setLoading(false);
	};

	return (
		<AuthLayout seoTitle="Sign Up - Layka" selectedImage={SignUpPhoto}>
			<div className="w-full h-full bg-white flex flex-col items-center justify-center ">
				<div className="flex flex-col max-w-[430px] w-full px-3  ">
					<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Sign Up</h1>
					<form className="flex flex-col items-center w-full mt-[50px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="flex flex-col w-full">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="First name *"
									{...register('firstName', {
										required: true,
									})}
								/>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">{errors.email?.type === 'required' ? 'Field is required!' : ''}</span>
						</div>
						<div className="flex flex-col w-full">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Last name *"
									{...register('lastName', {
										required: true,
									})}
								/>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">{errors.email?.type === 'required' ? 'Field is required!' : ''}</span>
						</div>
						<div className="flex flex-col w-full">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Email *"
									{...register('email', {
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Invalid email address',
										},
									})}
								/>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">
								{errors.email?.type === 'required' ? 'Field is required!' : ''}
								{errors.email?.type === 'pattern' ? 'Email is not valid!' : ''}
							</span>
						</div>
						<div className="flex flex-col w-full">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Password *"
									type={showPassword ? 'text' : 'password'}
									{...register('password', {
										required: true,
										minLength: 8,
										pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
									})}
									autoComplete="off"
									onChange={(e) => {
										setValue('password', e.target.value.toString(), { shouldValidate: true });
										if (getValues('repeatPassword') !== '') trigger('repeatPassword');
									}}
								/>
								<div className="cursor-pointer" onClick={toggleShowPassword}>
									{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
								</div>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">
								{errors.password?.type === 'required' ? 'Field is required!' : ''}
								{errors.password?.type === 'minLength' ? 'Šifra treba da ima najmanje 8 karaktera!' : ''}
								{errors.password?.type === 'pattern' ? 'Šifra treba da ima najmanje 8 karaktera, jedno veliko slovo i jedan broj' : ''}
							</span>
						</div>
						<div className="flex flex-col w-full ">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Confirm password *"
									type={showPassword ? 'text' : 'password'}
									{...register('repeatPassword', {
										validate: {
											obavezno: (value) => value.length > 0,
											najduze: (value) => value.length > 2,
											corect: (val: string) => getValues('password') === val,
										},
									})}
									autoComplete="off"
								/>
								<div className="cursor-pointer" onClick={toggleShowPassword}>
									{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
								</div>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">
								{errors.repeatPassword?.type === 'obavezno' ? 'Field is required!' : ''}
								{errors.repeatPassword?.type === 'corect' ? 'Lozinke se ne slažu molim Vas proverite.' : ''}
								{errors.repeatPassword?.type === 'najduze' ? 'Šifra treba da ima najmanje 3 karaktera!' : ''}
							</span>
						</div>

						<p className="text-redError text-[12px] leading-5 h-5 text-center">{!!errorMessage ? errorMessage : ''}</p>
						<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-[10px] uppercase" disabled={loading}>
							{loading ? <Loading height={25} /> : 'Sign up'}
						</button>
					</form>
					<p className=" text-[16px] text-black700 leading-5 font-normal  text-center mt-10 ">Already have an account?</p>
					<Link to="/login" className="w-full flex items-center justify-center  bg-white text-[16px] text-laykaDarkPurple font-bold cursor-pointer mt-[10px] ">
						Sign in
					</Link>
				</div>
			</div>
		</AuthLayout>
	);
}

export default RegistrationScreen;
