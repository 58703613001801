import { useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import SignInPhoto from '../../assets/images/SignIn.webp';
import SignUpPhoto from '../../assets/images/SignUp.webp';
import { useState } from 'react';
import Instructions from './login-screens/Instructions';
import Country from './login-screens/Country';
// import Country from './login-screens/Country';

export default function CountryScreen() {
	const [modalOpen, setModalOpen] = useState(false);

	const closeModal = () => {
		setModalOpen(false);
	};
	const openModal = () => {
		setModalOpen(true);
	};

	// React.useEffect(() => {
	// 	// containerRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
	// }, [selectedMode]);

	return (
		<div className=" w-full h-[100dvh]   grid grid-cols-1 xl:grid-cols-2   bg-white relative">
			<div className=" bg-white hidden xl:flex">
				<img src={SignInPhoto} alt="" className="w-full  object-cover h-full " />
			</div>
			<div className=" flex items-stretch min-h-0 py-10 xl:py-0">
				<Country openModal={openModal} />
			</div>

			{modalOpen && <Instructions closeModal={closeModal} />}
		</div>
	);
}
