import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import { SubmitHandler, useForm } from 'react-hook-form';
import Loading from '../../../common/Loading';
import AuthLayout from '../LoginLayout';
import ForgotPasswordPhoto from '../../../assets/images/ForgotPassword.webp';
import SendAgainBackground from '../../../assets/images/SendAgainBackground.webp';
import { ReactComponent as ShowPasswordIcon } from '../../../assets/icons/ShowPasswordIcon.svg';
import SignInPhoto from '../../../assets/images/SignIn.webp';
import { ReactComponent as HidePasswordIcon } from '../../../assets/icons/HidePasswordIcon.svg';
import { forgotPassword, resetPasswordAsync } from '../../../server/server';

type Inputs = {
	password: string;
	repeatPassword: string;
};

function NewPasswordScreen() {
	const navigate = useNavigate();
	const { setUser } = useUser();
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [currentState, setCurrentState] = useState('init');
	const [showPassword, setShowPassword] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get('token') ?? '';
	const email = searchParams.get('email') ?? '';

	// useEffect(() => {
	// 	if (!token || !email) {
	// 		navigate('/login');
	// 	}
	// }, [token]);

	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: {
			password: '',
			repeatPassword: '',
		},
	});

	const onSubmit: SubmitHandler<Inputs> = async () => {
		resetPassword();
	};
	const resetPassword = async () => {
		setLoading(true);
		const data = {
			password: getValues('password'),
			token,
			email,
		};
		const response = await resetPasswordAsync(data);
		if (response.status === 201) {
			const data = response.data;
			setCurrentState(currentState === 'init' ? 'check' : 'init');
		} else {
			setErrorMessage(response?.data?.message);
		}
		setLoading(false);
	};

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};
	return (
		<AuthLayout seoTitle="New Password - Layka" selectedImage={currentState == 'init' ? ForgotPasswordPhoto : SignInPhoto}>
			<div className="w-full h-full bg-white flex flex-col items-center justify-center">
				{currentState === 'init' && (
					<div className="flex flex-col max-w-[430px] w-full px-3 ">
						<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Please, enter a new password below.</h1>
						<form className="flex flex-col items-center w-full mt-[17px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<div className="flex flex-col w-full">
								<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
									<input
										className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
										placeholder="New Password"
										type={showPassword ? 'text' : 'password'}
										{...register('password', {
											required: true,
											minLength: 8,
											pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
										})}
										autoComplete="off"
									/>
									<div className="cursor-pointer" onClick={toggleShowPassword}>
										{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
									</div>
								</div>
								<span className="text-redError text-[12px]  h-5 ">
									{errors.password?.type === 'required' ? 'Field is required!' : ''}
									{errors.password?.type === 'minLength' ? 'Password must be at least 8 characters long!' : ''}
									{errors.password?.type === 'pattern' ? 'Password must be at least 8 characters long, contain one uppercase letter, and one number' : ''}
								</span>
							</div>
							<div className="flex flex-col w-full">
								<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center  mt-2">
									<input
										className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
										placeholder="Confirm Password"
										type={showPassword ? 'text' : 'password'}
										{...register('repeatPassword', {
											validate: {
												obavezno: (value) => value.length > 0,
												najduze: (value) => value.length > 2,
												corect: (val: string) => getValues('password') === val,
											},
										})}
										autoComplete="off"
									/>
									<div className="cursor-pointer" onClick={toggleShowPassword}>
										{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
									</div>
								</div>
								<span className="text-redError text-[12px]  h-5 ">
									{errors.repeatPassword?.type === 'obavezno' ? 'Field is required!' : ''}
									{errors.repeatPassword?.type === 'corect' ? 'Passwords do not match, please check.' : ''}
									{errors.repeatPassword?.type === 'najduze' ? 'Password must be at least 3 characters long!' : ''}
								</span>
							</div>

							<p className="text-redError text-[12px] leading-5 h-5 text-center">{!!errorMessage ? errorMessage : ''}</p>
							<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-1 uppercase" disabled={loading}>
								{loading ? <Loading height={25} /> : 'Send'}
							</button>
						</form>

						<Link to="/login" className="w-full flex items-center justify-center  bg-white text-[16px] text-laykaDarkPurple font-bold cursor-pointer mt-10 ">
							Back to login
						</Link>
					</div>
				)}
				{currentState === 'check' && (
					<div style={{ backgroundImage: `url(${SendAgainBackground})`, width: '100%', height: '100%', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className="flex flex-col items-center justify-center">
						<div className="flex flex-col max-w-[300px] w-full px-3 ">
							<p className="text-[30px] leading-[36px] text-black  font-bold  ">Password Change</p>
							<p className="text-[16px] leading-[24px] text-black  font-normal  mt-[20px]">Well done! You have successfully changed your password!</p>
							<Link to="/login" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-5 uppercase">
								take me to login
							</Link>
						</div>
					</div>
				)}
			</div>
		</AuthLayout>
	);
}

export default NewPasswordScreen;
