import React, { useEffect, useState } from 'react';
import { signUp, whoAmI } from '../../../server/server';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import Loading from '../../../common/Loading';
import { ReactComponent as ShowPasswordIcon } from '../../../assets/icons/ShowPasswordIcon.svg';
import { ReactComponent as HidePasswordIcon } from '../../../assets/icons/HidePasswordIcon.svg';

type Inputs = {
	token: any;
	email: any;
	password: string;
	repeatPassword: string;
};
interface SignIn {
	setSelectedMode: any;
}

function ResetPasswordScreen({ setSelectedMode }: SignIn) {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};
	let query1 = useQuery();
	const navigate = useNavigate();
	const { user, setUser } = useUser();
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [currentState, setCurrentState] = useState('init');

	useEffect(() => {
		setValue('email', query1.get('email'), { shouldValidate: true });
		setValue('token', query1.get('token'), { shouldValidate: true });
	}, []);

	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm<Inputs>({
		defaultValues: {
			token: '',
			email: '',
			password: '',
			repeatPassword: '',
		},
	});

	const onSubmit: SubmitHandler<Inputs> = async () => {
		console.log('change password data ', getValues('password'));
		let path = window.location.href;
		console.log('path ', path.includes('change-password'));
		if (path.includes('change-password')) {
			changePass();
		} else if (path.includes('resetPassword')) resetPass();
		setCurrentState(currentState === 'init' ? 'check' : 'init');
	};
	const resetPass = async () => {
		// setLoading(true);
		// const response = await resetPassword({
		// 	token: getValues('token'),
		// 	email: getValues('email'),
		// 	password: getValues('password'),
		// });
		// if (response.status === 201) {
		// 	notif('Nova lozinka je sačuvana');
		// 	navigate('/login');
		// } else {
		// 	console.log('reset pass error ', response.data);
		// 	notif('Došlo je do greške kod resetovanja lozinke');
		// }
		// setLoading(false);
	};
	const changePass = async () => {
		// setLoading(true);
		// const response = await changePassword(getValues('password'));
		// if (response.status === 201) {
		// 	notif('Lozinka je sačuvana');
		// 	navigate('/login');
		// } else {
		// 	console.log('reset pass error ', response.data);
		// 	notif('Došlo je do greške kod promene lozinke ');
		// }
		// setLoading(false);
	};

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<div className="w-full h-full bg-white flex flex-col items-center justify-center ">
			{currentState === 'init' && (
				<div className="flex flex-col max-w-[430px] w-full px-3  ">
					<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Please, enter a new password below.</h1>
					<form className="flex flex-col items-center w-full mt-[17px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="flex flex-col w-full">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Password *"
									type={showPassword ? 'text' : 'password'}
									{...register('password', {
										required: true,
										minLength: 8,
										pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
									})}
									autoComplete="off"
									onChange={(e) => {
										setValue('password', e.target.value.toString(), { shouldValidate: true });
										if (getValues('repeatPassword') !== '') trigger('repeatPassword');
									}}
								/>
								<div className="cursor-pointer" onClick={toggleShowPassword}>
									{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
								</div>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">
								{errors.password?.type === 'required' ? 'Field is required!' : ''}
								{errors.password?.type === 'minLength' ? 'Šifra treba da ima najmanje 8 karaktera!' : ''}
								{errors.password?.type === 'pattern' ? 'Šifra treba da ima najmanje 8 karaktera, jedno veliko slovo i jedan broj' : ''}
							</span>
						</div>
						<div className="flex flex-col w-full ">
							<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
								<input
									className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
									placeholder="Confirm password *"
									type={showPassword ? 'text' : 'password'}
									{...register('repeatPassword', {
										validate: {
											obavezno: (value) => value.length > 0,
											najduze: (value) => value.length > 2,
											corect: (val: string) => getValues('password') === val,
										},
									})}
									autoComplete="off"
								/>
								<div className="cursor-pointer" onClick={toggleShowPassword}>
									{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
								</div>
							</div>
							<span className="text-redError text-[12px] leading-5 h-5 ">
								{errors.repeatPassword?.type === 'obavezno' ? 'Field is required!' : ''}
								{errors.repeatPassword?.type === 'corect' ? 'Lozinke se ne slažu molim Vas proverite.' : ''}
								{errors.repeatPassword?.type === 'najduze' ? 'Šifra treba da ima najmanje 3 karaktera!' : ''}
							</span>
						</div>
						<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-[10px] uppercase" disabled={loading}>
							{loading ? <Loading height={25} /> : 'Change password'}
						</button>
					</form>
				</div>
			)}
			{currentState === 'check' && (
				<div className="flex flex-col max-w-[300px] w-full px-3 ">
					<img src="" alt="" />
					<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Password Change</h1>
					<h2 className="text-[16px] leading-[24px] text-black  font-normal  mt-[20px]">Well done! You have successfully changed your password!</h2>
					<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-5 uppercase" disabled={loading} onClick={() => setCurrentState('init')}>
						{loading ? <Loading height={25} /> : 'Take me to layka'}
					</button>
					<img src="" alt="" />
				</div>
			)}
		</div>
	);
}

export default ResetPasswordScreen;
